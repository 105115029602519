import preset from '@/@core/preset/preset';
import Vue from 'vue';
import VuetifyToast from 'vuetify-toast-snackbar-ng';
import Vuetify, {
  VBtn,
  VIcon,
  VSelect,
  VSnackbar,
  VTextField
} from 'vuetify/lib';

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
    VTextField,
    VSelect
  }
});

Vue.use(VuetifyToast, {
  x: 'right',
  y: 'top',
  timeout: 3000,
  showClose: true,
  closeIcon: 'mdi-close'
});

export default new Vuetify({
  preset,
  theme: {
    themes: {
      light: {
        primary: '#2B4BFF',
        secondary: '#8A8D93',
        success: '#12B76A',
        green: '#4CE09D'
      },
      dark: {
        primary: '#2B4BFF',
        secondary: '#8A8D93',
        success: '#12B76A',
        green: '#4CE09D'
      }
    },
    options: {
      customProperties: true,
      variations: false
    }
  }
});
