import {
  EPermissionSubject,
  TPermissionSubject,
  IPermissionConfig,
  TPermissionRuleKey
} from '@/plugins/role-permissions/types/permission-subject';

export enum ERole {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_OWNER = 'ROLE_ADMIN_OWNER',
  ROLE_FINANCE = 'ROLE_ADMIN_FINANCE',
  ROLE_MANAGER = 'ROLE_ADMIN_MANAGER',
  ROLE_ACCOUNT = 'ROLE_ACCOUNT',
  ROLE_OPERATOR_ADMIN = 'ROLE_OPERATOR_ADMIN',
  ROLE_OPERATOR_MARKETER = 'ROLE_OPERATOR_MARKETER',
  ROLE_OPERATOR_FINANCE = 'ROLE_OPERATOR_FINANCE',
  ROLE_OPERATOR_DEVELOPER = 'ROLE_OPERATOR_DEVELOPER',
  ROLE_OPERATOR_SUPPORT = 'ROLE_OPERATOR_SUPPORT',
  ROLE_OPERATOR_GUEST = 'ROLE_OPERATOR_GUEST',
  ROLE_OPERATOR_VIEWER = 'ROLE_OPERATOR_VIEWER',
  ROLE_GAME_PROVIDER = 'ROLE_GAME_PROVIDER',
  ROLE_COPY_STAKE_ACCOUNT = 'ROLE_COPY_STAKE_ACCOUNT',
  ROLE_OPERATOR_COPY_STAKE_ADMIN = 'ROLE_OPERATOR_COPY_STAKE_ADMIN',
  ROLE_OPERATOR_COPY_STAKE_SUPPORT = 'ROLE_OPERATOR_COPY_STAKE_SUPPORT'
}

export type TRoleName =
  | 'super-admin'
  | 'super-admin-owner'
  | 'super-admin-finance'
  | 'super-admin-manager'
  | 'account'
  | 'admin'
  | 'marketer'
  | 'finance'
  | 'developer'
  | 'support'
  | 'guest'
  | 'viewer'
  | 'game-provider'
  | 'copystake-account'
  | 'copystake-admin'
  | 'copystake-support';

export const roleNameKeyMap: { [key in TRoleName]: ERole } = {
  'super-admin': ERole.ROLE_ADMIN,
  'super-admin-owner': ERole.ROLE_OWNER,
  'super-admin-finance': ERole.ROLE_FINANCE,
  'super-admin-manager': ERole.ROLE_MANAGER,
  account: ERole.ROLE_ACCOUNT,
  admin: ERole.ROLE_OPERATOR_ADMIN,
  marketer: ERole.ROLE_OPERATOR_MARKETER,
  finance: ERole.ROLE_OPERATOR_FINANCE,
  developer: ERole.ROLE_OPERATOR_DEVELOPER,
  support: ERole.ROLE_OPERATOR_SUPPORT,
  guest: ERole.ROLE_OPERATOR_GUEST,
  viewer: ERole.ROLE_OPERATOR_VIEWER,
  'game-provider': ERole.ROLE_GAME_PROVIDER,
  'copystake-account': ERole.ROLE_COPY_STAKE_ACCOUNT,
  'copystake-admin': ERole.ROLE_OPERATOR_COPY_STAKE_ADMIN,
  'copystake-support': ERole.ROLE_OPERATOR_COPY_STAKE_SUPPORT
};

export enum EPermission {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete'
}

export type TPermissionRuleMain = Lowercase<keyof typeof EPermissionSubject>;

export type TRolePermission = Record<EPermission, boolean>;

export type TCheckPermission = (rule: TPermissionRuleKey) => boolean;

export type TRolePermissions = Partial<Record<EPermission, TCheckPermission>>;

export type TPermissionsAssignment = Partial<
  Record<EPermission, TCheckPermission>
>;

export interface TPermissionPlugin {
  can: Partial<Record<EPermission, TCheckPermission>>;
  is: (name: TRoleName | TRoleName[]) => boolean;
  name: ERole | '';
  permissions: TPermissionsAssignment;
  setPermissions: (role: string) => void;
}

export type TRolePermissionsAssignment = Record<string, TRolePermissions>;

export { IPermissionConfig, EPermissionSubject, TPermissionSubject };
