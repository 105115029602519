import Vue from 'vue';
import Router, { Route } from 'vue-router';
import { ERole } from '@/plugins/role-permissions/types';
import { getMsToTokenExpire } from '@/helpers/tokenHelpers';
import { isSuperAdmin } from '@/helpers/superAdminHelper';
import { isGameProviderAdmin } from '@/helpers/gameProviderAdminHelper';
import store from '@/store';

// Routes
import paths from './paths';
import { accountInfo, getUserInfo } from '@/api/Onboarding';
import { Location } from 'vue-router/types/router';

Vue.use(Router);

const AUTH_ROUTES = [
  'login',
  'registration',
  'create-account',
  'password-forgot',
  'password-recovery'
];

const router = new Router({
  base: '/',
  mode: 'history',
  routes: paths,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  setRouteTitle(getTitle(to));
  const { authorize } = to.meta;

  let token = store.state.Auth.token;

  if (getMsToTokenExpire(token) <= 0) {
    token = null;
  }

  if (
    token &&
    store.state.Auth.role !== ERole.ROLE_ADMIN &&
    !isGameProviderAdmin
  ) {
    const accountRoles: ERole[] = [
      ERole.ROLE_ACCOUNT,
      ERole.ROLE_COPY_STAKE_ACCOUNT
    ];
    const isAccount: boolean = accountRoles.includes(store.state.Auth.role);

    const handler = isAccount ? accountInfo : getUserInfo;

    if (!store.state.Onboarding.accountInfo) {
      const data = await handler();
      store.commit('Onboarding/setAccountInfo', data);
    }
  }

  if (to.name === 'NotFound') {
    return next();
  }

  const defaultRoute = getDefaultRouteByRole(store.state.Auth.role);
  //redirect to default page if user has token but try to go login/registration pages.
  if (AUTH_ROUTES.includes(to.name) && token) {
    return next(defaultRoute);
  }

  if (to.name === 'not-allowed' && !from.name && from.path === '/') {
    return next(defaultRoute);
  }

  if (authorize) {
    // Redirect to login page if user without token
    if (authorize && !token) {
      return next({ name: 'login' });
    }

    // check if route is restricted by role
    if (authorize.length && !authorize.includes(store.state.Auth.role)) {
      // role not authorised so redirect to home page
      return next({ name: 'not-allowed' });
    }
  }

  if (!to.name) {
    return next(defaultRoute);
  }

  next();
});

/**
 * Get default route based on user role.
 * @param role Current user role.
 */
export function getDefaultRouteByRole(role: string): string {
  if (isSuperAdmin) return '/accounts';
  if (isGameProviderAdmin) return '/copy-stake-stats';
  switch (role) {
    case ERole.ROLE_ACCOUNT:
    case ERole.ROLE_OPERATOR_ADMIN:
    case ERole.ROLE_OPERATOR_DEVELOPER:
    case ERole.ROLE_COPY_STAKE_ACCOUNT:
    case ERole.ROLE_OPERATOR_COPY_STAKE_ADMIN:
      return '/home';
    case ERole.ROLE_OPERATOR_MARKETER:
      return '/loyalty-promotion';
    case ERole.ROLE_OPERATOR_FINANCE:
      return '/billing-info';
    case ERole.ROLE_OPERATOR_SUPPORT:
    case ERole.ROLE_OPERATOR_GUEST:
      return '/users';
    case ERole.ROLE_OPERATOR_COPY_STAKE_SUPPORT:
      return '/copy-stake/guidance';
    default:
      return '/dashboard';
  }
}

/**
 * Get the title for head section based on
 * the data defined on the current route.
 * @param route Current route.
 */
function getTitle(route: Route) {
  return route?.meta?.title || 'Admin panel';
}

/**
 * Change the title in the head section.
 */
export function setRouteTitle(title: string): void {
  document.querySelector('title').innerHTML = title;
}

export function navigateTo(routeOptions: Location): void {
  router.push(routeOptions).catch((err) => {
    // silent navigation error
    if (err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
}

export function replaceWith(routeOptions: Location): void {
  router.replace(routeOptions).catch((err) => {
    // silent navigation error
    if (err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
}

export default router;
