import { ERole, EPermission, TRolePermission } from '../types';
import { ROLE_PERMISSIONS } from './permissions';

/**
 * Make current CRUD permission
 * @param {EPermission} key
 * @param {EPermission[]} permissions
 */
const makeRule = (key: EPermission, permissions?: EPermission[] | boolean) =>
  Array.isArray(permissions) ? permissions.includes(key) : permissions;

/**
 * Make CRUD permissions
 *
 * @param {EPermission[]} permissions
 */
const makePermissionRules = (
  permissions?: EPermission[] | boolean
): TRolePermission =>
  (Object.values(EPermission) as EPermission[]).reduce(
    (all, curr) => ({ ...all, [curr]: makeRule(curr, permissions) }),
    {} as TRolePermission
  );

const DEFAULT_RULES = Object.freeze(makePermissionRules());

export class UserRole {
  private _role: ERole;
  private _permissions: Record<string, TRolePermission>;

  constructor(role: ERole) {
    this._role = role;
    this._permissions = {};
    this.setPermissionsByRole();
  }

  /**
   * Make permission for current subject
   *
   * @param {string} key - subject name
   * @param {EPermission[]} permissions - permissions for subject
   * @private
   */
  private makePermission(
    key: string,
    permissions?: EPermission[] | boolean
  ): void {
    this._permissions[key] = makePermissionRules(permissions);
  }

  /**
   * Set user permission according to role
   * @private
   */
  private setPermissionsByRole(): void {
    this._permissions = {};
    Object.entries(ROLE_PERMISSIONS[this.role] || {}).forEach(
      ([key, value]) => {
        if (Array.isArray(value) || typeof value === 'boolean') {
          this.makePermission(key, value);
        } else {
          Object.entries(value).forEach(([childKey, childValue]) => {
            this.makePermission(`${key} ${childKey}`, childValue);
          });
        }
      },
      {}
    );
  }

  get permissions(): Record<string, TRolePermission> {
    return new Proxy(this._permissions, {
      get(target, key) {
        return target[key as string] || DEFAULT_RULES;
      }
    });
  }

  get role(): ERole {
    return this._role;
  }

  set role(role: ERole) {
    this._role = role;
    this.setPermissionsByRole();
  }
}
