import Vue from 'vue';
import { PERMISSIONS_KEY } from '@/plugins/role-permissions/core/constants';
import { roleNameKeyMap } from '@/plugins/role-permissions/types';

import {
  EPermission,
  ERole,
  TCheckPermission,
  TPermissionPlugin,
  TPermissionsAssignment,
  TRoleName,
  TRolePermissions
} from './types';

import { UserRole } from './core/UserRole';
import { TPermissionRuleKey } from '@/plugins/role-permissions/types/permission-subject';

/**
 * Param that indicates is plugin already installed
 * For avoiding of several invoke
 */
let IS_INIT = false;

export class RolePermissionsPlugin {
  protected plugin: TPermissionPlugin;
  protected user: UserRole;

  constructor() {
    this.plugin = Vue.observable({
      name: '',
      can: this.can,
      is: (name: TRoleName | TRoleName[]) => this.is(name),
      [PERMISSIONS_KEY]: {},
      setPermissions: (role: ERole): void => this.setPermissions(role)
    });
  }

  /**
   * Get permission rules for current type
   *
   * @example
   * $role.can.read('dashboard')
   */
  private get can(): TPermissionsAssignment {
    return new Proxy<TRolePermissions>(
      {},
      {
        get:
          (_, prop: EPermission): TCheckPermission =>
          (rule: TPermissionRuleKey) =>
            this.checkPermission(prop, rule)
      }
    );
  }
  /**
   * Check role
   *
   * @param {string|string[]} query - role or roles array to check
   *
   * @example
   * $role.is('admin')
   * $role.is(['admin', 'account'])
   *
   * @return boolean
   */
  private is(query: TRoleName | TRoleName[]): boolean {
    const check = (n: TRoleName): boolean => {
      const roleKey: ERole = roleNameKeyMap[n];
      const userRole: ERole = this.user?.role;

      return roleKey === userRole;
    };

    return Array.isArray(query) ? query.some(check) : check(query);
  }

  /**
   * Set permissions according to role
   *
   * @param {string} role - user role;
   */
  private setPermissions(role: ERole): void {
    Vue.set(this.plugin, 'name', role);
    if (!this.user) {
      this.user = new UserRole(role);
    } else {
      this.user.role = role;
    }

    Vue.set(this.plugin, PERMISSIONS_KEY, {});
    Object.entries(this.user[PERMISSIONS_KEY]).forEach(([rule, perm]) => {
      Vue.set(this.plugin[PERMISSIONS_KEY], rule, perm);
      Object.entries(perm).forEach(([permission, value]) =>
        Vue.set(this.plugin[PERMISSIONS_KEY][rule], permission, value)
      );
    });
  }

  /**
   * Check for a permissions for current type
   *
   * @param {EPermission} type - rule type (read/update/delete/create)
   * @param {string} permission - name of permission
   * @param {boolean} isHigh - is high level search
   * @private
   */
  private checkPermission(
    type: EPermission,
    permission: TPermissionRuleKey,
    isHigh = false
  ): boolean {
    if (permission in this.plugin[PERMISSIONS_KEY]) {
      return this.plugin[PERMISSIONS_KEY][permission][type];
    }

    if (isHigh) return false;

    const highLevelPermission = permission.split(' ')[0] as TPermissionRuleKey;

    if (highLevelPermission !== permission) {
      return this.checkPermission(type, highLevelPermission, true);
    } else {
      return Object.keys(this.plugin[PERMISSIONS_KEY]).some(
        (key) =>
          key.includes(`${highLevelPermission} `) &&
          this.checkPermission(type, key as TPermissionRuleKey)
      );
    }
  }

  /**
   * Method for inner vue installation of plugin
   * @param vm
   */
  static install(vm: typeof Vue): void {
    if (IS_INIT) return;

    vm.prototype.$role = new RolePermissionsPlugin().plugin;

    IS_INIT = true;
  }
}
