import {
  EPermission,
  EPermissionSubject,
  ERole,
  IPermissionConfig,
  TPermissionSubject
} from '@/plugins/role-permissions/types';
import {
  EPermissionSubjectHome,
  EPermissionSubjectSettings,
  EPermissionSubjectTokenManage,
  EPermissionSuperAdmin
} from '@/plugins/role-permissions/types/permission-subject';

const permissionKeys: EPermissionSubject[] = Object.values(
  EPermissionSubject
).filter((k: any) => isNaN(k));

const getDefaultPermissions = (
  isActive: TPermissionSubject = true
): Partial<IPermissionConfig> =>
  Object.fromEntries(permissionKeys.map((k) => [k, isActive]));

const defaultPermissions = {
  ...getDefaultPermissions(false),
  [EPermissionSubject.INVITE]: [EPermission.READ, EPermission.CREATE],
  [EPermissionSubject.API]: true
};

const ADMIN_PERMISSIONS = getDefaultPermissions();

const SUPER_ADMIN_PERMISSIONS = {
  ...getDefaultPermissions(),
  [EPermissionSuperAdmin.SUPER_ADMIN_CASTODY]: [EPermission.READ],
  [EPermissionSuperAdmin.SUPER_ADMIN_DEPLOY]: [EPermission.READ],
  [EPermissionSuperAdmin.SUPER_ADMIN_MINT]: true,
  [EPermissionSuperAdmin.SUPER_ADMIN_LIVEKIT_SETTINGS]: true,
  [EPermissionSubject.DASHBOARD]: [EPermission.READ]
};

const SUPER_ADMIN_OWNER_PERMISSION = {
  ...getDefaultPermissions(),
  [EPermissionSuperAdmin.SUPER_ADMIN_CASTODY]: true,
  [EPermissionSuperAdmin.SUPER_ADMIN_DEPLOY]: true,
  [EPermissionSuperAdmin.SUPER_ADMIN_MINT]: true,
  [EPermissionSubject.DASHBOARD]: [EPermission.READ]
};

const SUPER_ADMIN_FINANCE_PERMISSION = {
  ...getDefaultPermissions([EPermission.READ]),
  [EPermissionSubject.STREAMERS]: false,
  [EPermissionSuperAdmin.SUPER_ADMIN_CASTODY]: [EPermission.READ],
  [EPermissionSuperAdmin.SUPER_ADMIN_DEPLOY]: false,
  [EPermissionSuperAdmin.SUPER_ADMIN_MINT]: false
};

const SUPER_ADMIN_MANAGER_PERMISSION = {
  ...getDefaultPermissions([EPermission.READ]),
  [EPermissionSuperAdmin.SUPER_ADMIN_CASTODY]: [EPermission.READ],
  [EPermissionSuperAdmin.SUPER_ADMIN_DEPLOY]: false,
  [EPermissionSuperAdmin.SUPER_ADMIN_MINT]: false,
  [EPermissionSubject.STREAMERS]: true,
  [EPermissionSubject.TOKEN_MANAGE]: {
    [EPermissionSubjectTokenManage.WALLETS]: true,
    [EPermissionSubjectTokenManage.LIQUIDITY_POOL]: true
  }
};

const SUPPORT_PERMISSIONS: Partial<IPermissionConfig> = {
  ...defaultPermissions,
  [EPermissionSubject.INVITE]: false,
  [EPermissionSubject.STATS]: [EPermission.READ],
  [EPermissionSubject.PAYMENTS]: [EPermission.READ],
  [EPermissionSubject.USERS]: true,
  [EPermissionSubject.WITHDRAW]: true,
  [EPermissionSubject.STREAMERS]: true
};

const GUEST_PERMISSIONS: Partial<IPermissionConfig> = {
  ...defaultPermissions,
  [EPermissionSubject.INVITE]: false,
  [EPermissionSubject.USERS]: [EPermission.READ],
  [EPermissionSubject.STATS]: [EPermission.READ],
  [EPermissionSubject.PAYMENTS]: [EPermission.READ],
  [EPermissionSubject.WITHDRAW]: [EPermission.READ]
};

const MARKETER_PERMISSIONS: Partial<IPermissionConfig> = {
  ...defaultPermissions,
  [EPermissionSubject.HOME]: true,
  [EPermissionSubject.STATS]: [EPermission.READ],
  [EPermissionSubject.SETTINGS]: {
    [EPermissionSubjectSettings.LANGUAGE]: true,
    [EPermissionSubjectSettings.EVENT]: true
  },
  [EPermissionSubject.DASHBOARD]: [EPermission.READ],
  [EPermissionSubject.TOKEN_MANAGE]: [EPermission.READ],
  [EPermissionSubject.USERS]: [EPermission.READ],
  [EPermissionSubject.TOKEN_PROMOTION]: true,
  [EPermissionSubject.PROMO]: true,
  [EPermissionSubject.STREAMERS]: true
};

const DEVELOPER_PERMISSIONS: Partial<IPermissionConfig> = {
  ...defaultPermissions,
  [EPermissionSubject.HOME]: {
    [EPermissionSubjectHome.INTEGRATION]: true,
    [EPermissionSubjectHome.TOKEN]: true
  },
  [EPermissionSubject.ONBOARDING]: true
};

const FINANCE_PERMISSIONS: Partial<IPermissionConfig> = {
  ...defaultPermissions,
  [EPermissionSubject.STATS]: [EPermission.READ],
  [EPermissionSubject.PROMO]: [EPermission.READ],
  [EPermissionSubject.DASHBOARD]: [EPermission.READ],
  [EPermissionSubject.USERS]: true,
  [EPermissionSubject.BALANCE]: true,
  [EPermissionSubject.PAYMENTS]: true,
  [EPermissionSubject.WITHDRAW]: true
};

const VIEWER_PERMISSIONS: Partial<IPermissionConfig> = {
  [EPermissionSubject.PROMO]: [EPermission.READ],
  [EPermissionSubject.STATS]: [EPermission.READ],
  [EPermissionSubject.USERS]: [EPermission.READ],
  [EPermissionSubject.BALANCE]: [EPermission.READ],
  [EPermissionSubject.WITHDRAW]: [EPermission.READ],
  [EPermissionSubject.DASHBOARD]: [EPermission.READ],
  [EPermissionSubject.TOKEN_MANAGE]: [EPermission.READ],
  [EPermissionSubject.STREAMERS]: [EPermission.READ]
};

const GAME_PROVIDER_PERMISSIONS: Partial<IPermissionConfig> = {
  ...getDefaultPermissions(false)
};

const COPYSTAKE_ADMIN_PERMISSIONS: Partial<IPermissionConfig> = {
  ...defaultPermissions,
  [EPermissionSubject.HOME]: {
    [EPermissionSubjectHome.INTEGRATION]: true,
    [EPermissionSubjectHome.COLORS]: true
  },
  [EPermissionSubject.USERS]: true,
  [EPermissionSubject.ONBOARDING]: true,
  [EPermissionSubject.STREAMERS]: true,
  [EPermissionSubject.SETTINGS]: {
    [EPermissionSubjectSettings.LANGUAGE]: true,
    [EPermissionSubjectSettings.ROlES]: true
  },
  [EPermissionSubject.API]: false
};

const COPYSTAKE_SUPPORT_PERMISSIONS: Partial<IPermissionConfig> = {
  ...defaultPermissions,
  [EPermissionSubject.STREAMERS]: [EPermission.READ],
  [EPermissionSubject.USERS]: [EPermission.READ],
  [EPermissionSubject.INVITE]: false,
  [EPermissionSubject.API]: false
};

export const ROLE_PERMISSIONS: Readonly<
  Record<ERole, Partial<IPermissionConfig>>
> = Object.freeze({
  [ERole.ROLE_ADMIN]: SUPER_ADMIN_PERMISSIONS,
  [ERole.ROLE_OWNER]: SUPER_ADMIN_OWNER_PERMISSION,
  [ERole.ROLE_FINANCE]: SUPER_ADMIN_FINANCE_PERMISSION,
  [ERole.ROLE_MANAGER]: SUPER_ADMIN_MANAGER_PERMISSION,
  [ERole.ROLE_ACCOUNT]: ADMIN_PERMISSIONS,
  [ERole.ROLE_OPERATOR_ADMIN]: ADMIN_PERMISSIONS,
  [ERole.ROLE_OPERATOR_SUPPORT]: SUPPORT_PERMISSIONS,
  [ERole.ROLE_OPERATOR_GUEST]: GUEST_PERMISSIONS,
  [ERole.ROLE_OPERATOR_FINANCE]: FINANCE_PERMISSIONS,
  [ERole.ROLE_OPERATOR_MARKETER]: MARKETER_PERMISSIONS,
  [ERole.ROLE_OPERATOR_DEVELOPER]: DEVELOPER_PERMISSIONS,
  [ERole.ROLE_GAME_PROVIDER]: GAME_PROVIDER_PERMISSIONS,
  [ERole.ROLE_OPERATOR_VIEWER]: VIEWER_PERMISSIONS,
  [ERole.ROLE_COPY_STAKE_ACCOUNT]: COPYSTAKE_ADMIN_PERMISSIONS,
  [ERole.ROLE_OPERATOR_COPY_STAKE_ADMIN]: COPYSTAKE_ADMIN_PERMISSIONS,
  [ERole.ROLE_OPERATOR_COPY_STAKE_SUPPORT]: COPYSTAKE_SUPPORT_PERMISSIONS
});

export default ROLE_PERMISSIONS;
